.grid-list {
    display: grid;
    gap: 30px;
  }

li{
    list-style-type: none;
}
.course-card {
    position: relative;
    background-color: var(--white);
    border-radius: var(--radius-6);
    overflow: hidden;
}

figure {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
}

.course-card .card-banner img {
    transition: var(--transition-2);
}
.img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.course-card .card-banner {
    background-color: var(--light-gray);
    aspect-ratio: 370 / 270;
    overflow: hidden;
}

.course-card .card-actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.course-card .card-content {
    padding: 25px 20px;
}
.course-card .card-meta-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}
.bg-overlay-section{
    background: url("../images/clientlogo/cta-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.custom-accordionwithicon .accordion-button1::after {
    background-image: none !important;
    font-family: "Material Design Icons";
    content: "+";
    font-size: 1.1rem;
    vertical-align: middle;
    line-height: 0.8;
}
.custom-accordionwithicon .accordion-button:not(.collapsed)::after {
    background-image: none !important;
    content: "-";
    margin-right: -3px;
}

/* Add this CSS to your stylesheets */
/* Adjust the styles based on your design preferences */

.editor-wrapper {
    position: relative;
  }
  
  .toolbarClassName {
    position: sticky;
    top: 0;
    z-index: 1000; /* Adjust z-index as needed */
    background-color: #fff; /* Set the background color */
    width: 100%;
  }
  
  .wrapperClassName {
    margin-top: 0px; /* Adjust margin-top to accommodate the fixed toolbar height */
    padding: 1px;
    border: 1px solid #ced4da;
    background-clip: padding-box;
  }
  .editorClassName{
    padding-left: 10px;
  }
  